import React, { FC } from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { OfferTemplate } from "@templates/OfferTemplate";
import { UniversalSection } from "@components/molecules/UniversalSection";
import { ChildImageSharp } from "@interfaces/childImageSharp";
import WhatWeCanDoMore from "@components/organisms/WhatWeCanDoMore";

const Animations3d: FC<Props> = ({ data }) => {
  const { t } = useTranslation("offer");
  const { t: ct } = useTranslation("common");

  return (
    <OfferTemplate
      title={ct("title-offer-3d-animations")}
      description={ct("description-offer-3d-animations")}
      heroTitle={t("3d-animations.hero.title")}
      heroDescription={t("3d-animations.hero.description", {
        returnObjects: true,
      })}
      heroVideo="https://www.youtube.com/embed/Lpum9QEF-DM?autoplay=1&frameborder=0&loop=1&playlist=Lpum9QEF-DM&mute=1"
    >
      <UniversalSection
        title={t("3d-animations.sections.0.title")}
        description={t("3d-animations.sections.0.description", {
          returnObjects: true,
        })}
        video="https://www.youtube.com/embed/s5ERYsKDtJE"
        fromRight
      />
      <UniversalSection
        title={t("3d-animations.sections.1.title")}
        description={t("3d-animations.sections.1.description", {
          returnObjects: true,
        })}
        images={[data.firstSectionImage.childImageSharp.gatsbyImageData]}
      />
      <UniversalSection
        title={t("3d-animations.sections.2.title")}
        description={t("3d-animations.sections.2.description", {
          returnObjects: true,
        })}
        images={[data.secondSectionImage.childImageSharp.gatsbyImageData]}
        fromRight
      />
      <UniversalSection
        title={t("3d-animations.sections.3.title")}
        description={t("3d-animations.sections.3.description", {
          returnObjects: true,
        })}
        images={[data.thirdSectionImage.childImageSharp.gatsbyImageData]}
      />
      <UniversalSection
        title={t("3d-animations.sections.4.title")}
        description={t("3d-animations.sections.4.description", {
          returnObjects: true,
        })}
        fromRight
        video="https://osiedlepodmiastem.pl/wirtualny-spacer/"
      />
      <WhatWeCanDoMore exclude={4} />
    </OfferTemplate>
  );
};

interface Props {
  data: {
    firstSectionImage: ChildImageSharp;
    secondSectionImage: ChildImageSharp;
    thirdSectionImage: ChildImageSharp;
  };
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    firstSectionImage: file(name: { eq: "offer-3d-animations_0" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: BLURRED)
      }
    }

    secondSectionImage: file(name: { eq: "offer-3d-animations_1" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: BLURRED)
      }
    }

    thirdSectionImage: file(name: { eq: "offer-3d-animations_2" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: BLURRED)
      }
    }
  }
`;

export default Animations3d;
